// Generated by Framer (55d768b)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["k99pVzhcs"];

const variantClassNames = {k99pVzhcs: "framer-v-rmuf05"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "k99pVzhcs", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "k99pVzhcs", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AGW6S", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-rmuf05", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"k99pVzhcs"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-1stdvhl"} data-framer-name={"emolife_events_iconen_running"} fill={"black"} intrinsicHeight={100} intrinsicWidth={111} layoutDependency={layoutDependency} layoutId={"IHGzMUCFL"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 110.7 100.4\" xml:space=\"preserve\"><circle fill=\"#F58229\" cx=\"84.8\" cy=\"10.3\" r=\"10.3\"/><path fill=\"#F58229\" d=\"M108.3 18.8c-2.3-1.5-5.4-.7-6.9 1.6l-7.1 11.5c-.3.4-.8.6-1.2.3L62.8 13.5C57.7 10.4 51 12 47.8 17l-9.3 14.8c-1.5 2.3-.8 5.4 1.6 6.9 2.3 1.5 5.4.8 6.9-1.6l9.3-14.8c.3-.4.8-.5 1.2-.3l4.2 2.6-15 24.2c-1 1.6-1.2 3.6-.7 5.4.6 1.8 1.9 3.3 3.6 4l5.1 2.2c-.7.5-1.3 1.1-1.8 1.8L34.7 90.4c-2 3-1.1 7 1.9 9 1.1.7 2.3 1.1 3.5 1.1 2.1 0 4.2-1 5.5-3L54.4 84l25.7-15.6c2.1-1.3 3.3-3.6 3.1-6-.2-2.4-1.7-4.6-3.9-5.5l-6.4-2.7 9.7-16.6 5.2 3.2c1.8 1.1 3.7 1.6 5.7 1.6 3.6 0 7.2-1.8 9.3-5.1l7.1-11.5c1.5-2.4.7-5.5-1.6-7z\"/><path fill=\"#F58229\" d=\"M58.4 50.8H5c-2.8 0-5-2.2-5-5s2.2-5 5-5h53.4c2.8 0 5 2.2 5 5s-2.3 5-5 5zm-6.9 21.4H17.4c-2.8 0-5-2.2-5-5s2.2-5 5-5h34.1c2.8 0 5 2.2 5 5s-2.2 5-5 5z\" opacity=\".3\"/></svg>"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AGW6S [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AGW6S .framer-9amobe { display: block; }", ".framer-AGW6S .framer-rmuf05 { height: 100px; overflow: hidden; position: relative; width: 111px; }", ".framer-AGW6S .framer-1stdvhl { aspect-ratio: 1.11 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 100px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 111
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerIUbxDF5pa: React.ComponentType<Props> = withCSS(Component, css, "framer-AGW6S") as typeof Component;
export default FramerIUbxDF5pa;

FramerIUbxDF5pa.displayName = "emolife_events_iconen_running";

FramerIUbxDF5pa.defaultProps = {height: 100, width: 111};

addFonts(FramerIUbxDF5pa, [])